export default (url: any, options?: any, passedThroughRequest?: any) => {
    const defaultOptions = {
        credentials: 'same-origin',
        headers: {
            'content-type': 'application/json'
        },
        method: 'GET'
    };

    if (passedThroughRequest?.headers?.cookie) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultOptions.headers.cookie = passedThroughRequest.headers.cookie;
    }

    return fetch(url, {
        ...defaultOptions,
        ...options
    }).then((response) => {
        if (!response.ok) {
            const err = new Error(`Error fetching ${url}: ${response.status}`);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            err.response = response;

            throw err;
        }

        return response;
    });
};
