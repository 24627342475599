import {OK} from 'http-status';
import getConfig from 'next/config';

import fetchService from './fetch-service';

export const getCustomer = async () => {
    const prefix = getConfig().publicRuntimeConfig.routePrefix;
    const response = await fetchService(`${prefix}/api/customer`);

    if (response.status !== OK) {
        return null;
    }

    return response.json();
};
