import React from 'react';
import {ThemeProvider} from 'styled-components';
import {hyvee} from '@hy-vee/themes';
import {GlobalStyle} from '@hy-vee/web-core/lib/global';

import {ScreenSizeProvider} from '../contexts/screen-context';

const PageLayout = ({children}) => (
    <>
        <GlobalStyle />
        <ScreenSizeProvider>
            <ThemeProvider theme={hyvee}>
                {children}
            </ThemeProvider>
        </ScreenSizeProvider>
    </>
);

export default PageLayout;
