import React, {ReactNode, createContext, useContext, useMemo} from 'react';
import {useQuery} from '@apollo/client';

import {GetAislesOnlineCart_carts} from '../autogen/GetAislesOnlineCart';
import {getAislesOnlineCart} from '../../src/graphql/queries/cart-queries';
import {THREE_LEGGED_AUTH_TYPE} from '../enums/auth-types';

import {useCustomerContext} from './customer-context';

type CartContextData = {cart: GetAislesOnlineCart_carts, loading: boolean};

export const CartContext = createContext<null | CartContextData>(null);

export const useCartContext = () => {
    const ctx = useContext<CartContextData>(CartContext);

    if (!ctx) {
        throw new Error(
            'Unable to create cart context, please ensure your component is within the CartProvider'
        );
    }

    return ctx;
};

const CartContextProvider = ({children}: {children: ReactNode}) => {
    const {customer} = useCustomerContext();

    const {data: cartData, loading: cartLoading} = useQuery(getAislesOnlineCart, {
        skip: !customer?.customerId,
        variables: {
            authType: THREE_LEGGED_AUTH_TYPE,
            customerId: Number.parseInt(customer?.customerId ?? '0')
        }
    });

    const value = useMemo(() => ({
        cart: cartData,
        loading: cartLoading
    }), [cartData, cartLoading]);

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    );
};

export default CartContextProvider;
