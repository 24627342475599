import React from 'react';
import Head from 'next/head';
import {ApolloProvider} from '@apollo/client';
import '@hy-vee/design-tokens/src/tokens.css';
import '@hy-vee/design-system/lib/design-system.css';
import '@hy-vee/global-navigation/lib/cjs/index.css';

import {useApollo} from '../graphql/use-apollo';
import {CustomerProvider} from '../contexts/customer-context';
import CartProvider from '../contexts/cart-context';
import PageLayout from '../components/page-layout';
import {googleTagManagerScriptNonce} from '../views/authentication/google-tag-manager-wrapper';
import {logWebVitals} from '../utils/log-web-vitals';
import FeatureToggleProvider from '../contexts/feature-toggle/provider';
import LiveChatProvider from '../components/live-chat/live-chat-provider';

import '../sass/index.scss';

const App = (props: any) => {
    const {Component, nonce, ...pageProps} = props;
    const apolloClient = useApollo(pageProps);

    return (
        <CustomerProvider pageComponent={Component}>
            <Head>
                <title>{'Hy-Vee'}</title>
                <meta charSet="utf-8" />
                <meta
                    content="IE=edge"
                    httpEquiv="X-UA-Compatible"
                />
                <meta
                    content="width=device-width, initial-scale=1"
                    name="viewport"
                />
                {googleTagManagerScriptNonce(nonce)}
            </Head>
            <LiveChatProvider />
            <PageLayout>
                <ApolloProvider client={apolloClient}>
                    <CartProvider>
                        <FeatureToggleProvider>
                            <Component {...pageProps} />
                        </FeatureToggleProvider>
                    </CartProvider>
                </ApolloProvider>
            </PageLayout>
        </CustomerProvider>
    );
};

App.getInitialProps = async ({
    Component,
    ctx
}: any) => {
    const {serverSideNonce} = ctx.query;

    let componentProps = {};

    if (Component.getInitialProps) {
        componentProps = await Component.getInitialProps(ctx);
    }

    return {
        ...componentProps,
        nonce: serverSideNonce
    };
};

export const reportWebVitals = logWebVitals;

export default App;
