import Script from 'next/script';

const LiveChatProvider = () => (
    <Script
        src="https://service.force.com/embeddedservice/5.0/esw.min.js"
        strategy="beforeInteractive"
        type="text/javascript"
    />
);

export default LiveChatProvider;
