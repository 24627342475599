import React, {useState, useEffect, useContext} from 'react';

import {getCustomer} from '../services/customer-service';
import AuthIframe, {useAuthIframeMessaging} from '../components/auth-iframe';

export interface ICustomerContext {
    customer: {
        customerUuid: string;
        firstName: string;
        lastName: string;
        fuelSaverCard: {
            fuelSaverCardUuid: string;
        };
        customerId: string;
        fuelSaverNumber: number;
        nationalOffersLinkedPaypalAccount: {
            emailAddress: string;
        } | null,
        store: {
            storeId: number;
        };
    } | null;
    isAuthenticated: boolean;
    loading: boolean;
}

const CustomerContext = React.createContext<ICustomerContext | undefined>(undefined);
const {Provider, Consumer} = CustomerContext;

CustomerContext.displayName = 'CustomerContext';

export const useCustomerContext = (): ICustomerContext => {
    const context = useContext(CustomerContext);

    if (!context) {
        throw new Error(
            'Unable to create customer context, please ensure your component is within the CustomerProvider'
        );
    }

    return context;
};

const CustomerProvider = ({children, pageComponent}) => {
    const wantsCustomerContext = pageComponent.wantsCustomerContext ? pageComponent.wantsCustomerContext() : false;
    const [customer, setCustomer] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [customerLoadAttempted, setCustomerLoadAttempted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listenerReady, setListenerReady] = useState(false);

    useEffect(() => {
        if (wantsCustomerContext && !customerLoadAttempted) {
            setLoading(true);
            getCustomer()
                .then((response) => {
                    setCustomer(response);
                    setIsAuthenticated(Boolean(response));
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                    setCustomerLoadAttempted(true);
                });
        } else {
            setLoading(false);
        }
    }, [wantsCustomerContext, customerLoadAttempted]);

    useAuthIframeMessaging({
        customerLoadAttempted,
        isAuthenticated,
        setCustomerLoadAttempted,
        setListenerReady,
        wantsCustomerContext
    });

    return (
        <Provider
            value={{
                customer,
                isAuthenticated,
                loading
            }}
        >
            {children}
            <AuthIframe
                customerLoadAttempted={customerLoadAttempted}
                isAuthenticated={isAuthenticated}
                listenerReady={listenerReady}
                wantsCustomerContext={wantsCustomerContext}
            />
        </Provider>
    );
};

export {CustomerProvider, Consumer as CustomerConsumer};
