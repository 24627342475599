import {ApolloClient, defaultDataIdFromObject, from, InMemoryCache} from '@apollo/client';

import {isServerSide} from '../utils/env';

import {getHeadersMiddleware, getHttpLink} from './graphql-client-utils';

let apolloClient: any;

const options = {
    query: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    },
    watchQuery: {
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache'
    }
};

const createClient = (initialState = {}, ssrContext = null) =>
    new ApolloClient({
        cache: new InMemoryCache({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cacheRedirects: {
                Query: {
                    unleashFeatureToggles: (_: any, args: any, {
                        getCacheKey
                    }: any) =>
                        args.featureNames.map((featureName: any) => getCacheKey({
                            __typename: 'UnleashFeatureToggle',
                            featureName
                        })
                        )
                }
            },
            dataIdFromObject: (object) => {
                if (object.__typename === 'UnleashFeatureToggle') {
                    return `unleashFeatureToggles:${object['featureName']}`;
                }

                if (object.__typename === 'CustomerMembership') {
                    return `customerMembership:${object['customerMembershipId']}`;
                }

                return object[`${object.__typename}Id`]
                    ? `${object.__typename}:${object[`${object.__typename}Id`]}`
                    : defaultDataIdFromObject(object);
            }
        }).restore(initialState),
        connectToDevTools: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultOptions: options,
        link: from([getHeadersMiddleware(ssrContext), getHttpLink()]),
        name: 'hy-vee-next',
        ssrMode: Boolean(ssrContext)
    });

export const graphqlClient = (initialState?: any, ssrContext?: any) => {
    if (isServerSide()) {
        return createClient(initialState, ssrContext);
    }

    if (!apolloClient || initialState) {
        apolloClient = createClient(initialState);

        return apolloClient;
    }

    return apolloClient;
};
