import React, {useCallback, useMemo} from 'react';
import getConfig from 'next/config';
import {useQuery} from '@apollo/client';

import * as featuresToggleNames from '../../enums/feature-toggle-names';
import {GetUnleashFeatureToggles} from '../../autogen/GetUnleashFeatureToggles';
import {getUnleashFeatures} from '../../../src/graphql/queries/feature-queries';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../../enums/auth-types';
import {useCustomerContext} from '../customer-context';

import FeatureToggleContext from './context';

export const allFeatures = Object.values(featuresToggleNames);

/**
 * Feature names from [feature toggles file]{@link featuresToggleNames}
 */
export type FeatureNameType = typeof allFeatures[number];

const buildDefaultMap = (): Map<FeatureNameType, boolean> => new Map(allFeatures.map((feature) => [feature, false]));

const FeatureToggleProvider: React.FC = ({children}) => {
    const {isAuthenticated, customer, loading: customerLoading} = useCustomerContext();
    const storeId = customer?.store?.storeId;
    const store = useMemo(() => storeId ?? getConfig().publicRuntimeConfig.defaultStoreId, [storeId]);

    const {data, loading: queryLoading, error: queryError} = useQuery<GetUnleashFeatureToggles>(getUnleashFeatures, {
        skip: customerLoading,
        variables: {
            authType: isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            featureNames: allFeatures,
            properties: {
                storeCode: store ? store.toString() : null
            }
        }
    });

    const loading = useMemo(() => Boolean((customerLoading || queryLoading) && !data && !queryError), [
        queryLoading,
        customerLoading,
        data,
        queryError
    ]);

    const error = useMemo(() => {
        if (!queryLoading && queryError) {
            return queryError;
        }

        return undefined;
    }, [queryLoading, queryError]);

    const featureMap = useMemo(() => {
        if (!queryLoading && data && data.unleashFeatureToggles) {
            const featureToggles = data.unleashFeatureToggles;

            return new Map<FeatureNameType, boolean>(
                featureToggles.map(({active, featureName}) => [featureName as FeatureNameType, active])
            );
        }

        return buildDefaultMap();
    }, [data, queryLoading]);

    const featureEnabled = useCallback((feature: FeatureNameType) => featureMap.get(feature) === true, [featureMap]);

    const featuresEnabled = useCallback((features: FeatureNameType[]) => features.every(featureEnabled), [
        featureEnabled
    ]);

    const providerValue = useMemo(() => ({
        error,
        featureEnabled,
        featureMap,
        featuresEnabled,
        loading
    }), [error, featureEnabled, featureMap, featuresEnabled, loading]);

    return (
        <FeatureToggleContext.Provider
            value={providerValue}
        >
            {children}
        </FeatureToggleContext.Provider>
    );
};

export default FeatureToggleProvider;
