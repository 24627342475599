import React, {useState, useEffect} from 'react';

import {
    isServerSide,
    documentWindow
} from '../utils/env';

interface IWindowSize {
    screenHeight: number;
    screenWidth: number;
}

const ScreenSizeContext = React.createContext<IWindowSize | undefined>(undefined);
const {Provider, Consumer} = ScreenSizeContext;

ScreenSizeContext.displayName = 'ScreenSizeContext';

const ScreenSizeProvider = ({children, fixedScreenHeight, fixedScreenWidth}) => {
    const [windowSize, setWindowSize] = useState({
        screenHeight: fixedScreenHeight || 0,
        screenWidth: fixedScreenWidth || 0
    });

    useEffect(() => {
        if (!isServerSide()) {
            setWindowSize({
                screenHeight: fixedScreenHeight || documentWindow().innerHeight,
                screenWidth: fixedScreenWidth || documentWindow().innerWidth
            });

            documentWindow().addEventListener('resize', () => {
                setWindowSize({
                    screenHeight: fixedScreenHeight || documentWindow().innerHeight,
                    screenWidth: fixedScreenWidth || documentWindow().innerWidth
                });
            });
        }
    }, [fixedScreenHeight, fixedScreenWidth]);

    return (
        <Provider value={windowSize}>
            {children}
        </Provider>
    );
};

export {
    ScreenSizeProvider,
    Consumer as ScreenSize
};
