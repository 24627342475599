import React, {useEffect} from 'react';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
    display: none;
`;

const AuthIframe = ({wantsCustomerContext, isAuthenticated, customerLoadAttempted, listenerReady}) => {
    if (wantsCustomerContext && !isAuthenticated && customerLoadAttempted && listenerReady) {
        return (
            <StyledIframe
                aria-hidden
                src="/pages/keycloak-iframe"
                title="Hidden Auth IFrame"
            />);
    }

    return null;
};

/**
 * Functional component representing an authentication iframe.
 * @param {Object} props - Component props.
 * @param {boolean} props.wantsCustomerContext
 * @param {boolean} props.isAuthenticated
 * @param {boolean} props.customerLoadAttempted
 * @returns {null}
 */
export const useAuthIframeMessaging = ({wantsCustomerContext, isAuthenticated, customerLoadAttempted, setCustomerLoadAttempted, setListenerReady}) => {
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === 'auth-checked') {
                window.removeEventListener('message', handleMessage);
                setCustomerLoadAttempted(false);
                setListenerReady(false);
            }
        };

        if (wantsCustomerContext && !isAuthenticated) {
            window.addEventListener('message', handleMessage);
            setListenerReady(true);
        }

        return () => {
            window.removeEventListener('message', handleMessage);
            setListenerReady(false);
        };
    }, [wantsCustomerContext, isAuthenticated, customerLoadAttempted, setCustomerLoadAttempted, setListenerReady]);
};

export default AuthIframe;
