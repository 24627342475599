import getConfig from 'next/config';
import {split, ApolloLink, HttpLink, Operation} from '@apollo/client';

import {TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {isServerSide} from '../utils/env';

import graphqlFetch from './graphql-fetch-service';

const getUrl = (operation: Operation, twoLegged: boolean) => {
    const routePrefix = getConfig().publicRuntimeConfig.routePrefix;

    const baseUrl = !isServerSide() ? getConfig().publicRuntimeConfig.baseUrl : 'http://localhost:3000';
    const path = `${routePrefix}/api/graphql/${operation.operationName}/${twoLegged ? 'two-legged' : 'three-legged'}`;

    const url = new URL(path, baseUrl);

    if (operation.variables.routeCacheKey) {
        url.searchParams.set('routeCacheKey', operation.variables.routeCacheKey);
    }

    return url.toString();
};

export const getHeadersMiddleware = (ssrContext?: any) => {
    const cookie = !ssrContext ? {} : {cookie: ssrContext.req.headers.cookie};

    return new ApolloLink((operation, forward) => {
        operation.setContext(({headers = {}}) => ({
            headers: {
                ...headers,
                ...cookie
            }
        }));

        return forward(operation);
    });
};

export const getHttpLink = () => {
    const options = {
        credentials: 'same-origin',
        fetch: graphqlFetch
    };

    const twoLeggedLink = new HttpLink({
        ...options,
        uri: (operation) => getUrl(operation, true)
    });

    const threeLeggedLink = new HttpLink({
        ...options,
        uri: (operation) => getUrl(operation, false)
    });

    return split(
        (operation) => operation.variables.authType === TWO_LEGGED_AUTH_TYPE,
        twoLeggedLink,
        threeLeggedLink
    );
};
