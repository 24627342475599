import gql from 'graphql-tag';

export const cartItemFragment = gql`
    fragment ICartItem on cartItem {
        cartItemId
        cartId
        quantity
        quantityType
        storeProduct {
            storeProductId
            product {
                averageWeight
                productId
            }
        }
    }
`;

export const getMadeToOrderCart = gql`
    query GetMadeToOrderCart(
        $cartId: String!
    ) {
        madeToOrderCart(cartId: $cartId) {
            cartId
            cartItems: madeToOrderCartItems {
                cartItemId
                quantity
            }
        }
    }
`;

export const getAislesOnlineCart = gql`
    query GetAislesOnlineCart(
        $customerId: Int!
    ) {
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            cartItems {
                ...ICartItem
            }
            store {
                legacyStoreId
            }
        }
    }
    ${cartItemFragment}
`;
