import gql from 'graphql-tag';

export const getUnleashFeatures = gql`
    query GetUnleashFeatureToggles($featureNames: [String!]!, $properties: UnleashProperties) {
        unleashFeatureToggles(featureNames: $featureNames, properties: $properties) {
            active
            featureName
            properties {
                storeCode
            }
        }
    }
`;
