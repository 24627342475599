const graphqlFetch = (url: any, options?: any) =>
    fetch(url, {
        ...options,
        headers: {
            'content-type': 'application/json'
        }
    }).then((response) => {
        if (!response.ok) {
            const err = new Error(`Error fetching ${url}`);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            err.response = response;

            throw err;
        }

        return response;
    });

export default graphqlFetch;
